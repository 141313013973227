@import "_reset";
@import "_mixin";
@import "_spacing";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Variable
$border: rgba(102, 119, 136, 0.1);

$border-radius-xs: 4px;
$border-radius-s: 8px;
$border-radius-m: 16px;
$border-radius-l: 50px;
$border-radius-xl: 50%;

// Override

:root {
  font-size: 16px;
}

body {
  font-family: "Montserrat", sans-serif;
  background: #000000;
  line-height: 1.7;
  font-weight: 500;
  color: #ffffff;
}

img {
  max-width: 100%;
}

.col {
  display: flex;
  flex-direction: column;

  .full {
    flex-grow: 1;
  }
}
.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.666%;
}
.col-7 {
  width: 60%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 40%;
}
.col-4 {
  width: 33.333%;
}
.col-3 {
  width: 25%;
}

.bd-t {
  border-top: 1px solid $border;
}
.bd-b {
  border-bottom: 1px solid $border;
}
.bd-l {
  border-left: 1px solid $border;
}
.bd-r {
  border-right: 1px solid $border;
}
.bd-a {
  border: 1px solid $border;
}
.bd-x {
  border-left: 1px solid $border;
  border-right: 1px solid $border;
}
.bd-y {
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
}

.br-t {
  border-top-left-radius: $border-radius-s !important;
  border-top-right-radius: $border-radius-s !important;
}
.br-b {
  border-bottom-left-radius: $border-radius-s !important;
  border-bottom-right-radius: $border-radius-s !important;
}
.br-l {
  border-top-left-radius: $border-radius-s !important;
  border-bottom-left-radius: $border-radius-s !important;
}
.br-r {
  border-top-right-radius: $border-radius-s !important;
  border-bottom-right-radius: $border-radius-s !important;
}
.br-a {
  border-radius: $border-radius-s !important;
}

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}

.p-relative {
  position: relative !important;
}

.d-flex {
  display: flex !important;

  &.flex-column {
    flex-direction: column;
  }
  &.flex-column-reverse {
    flex-direction: column-reverse;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-space-around {
    justify-content: space-around;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-baseline {
    align-items: baseline;
  }
  &.align-stretch {
    align-items: stretch;
  }
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0 !important;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: flex-end;
}

.justify-self-start {
  justify-self: flex-start;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-end {
  justify-self: flex-end;
}

.bg-transparent {
  background: transparent !important;
}

.fade-mask {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: auto;
    bottom: -100px;
    width: 100%;
    height: 100px;
    z-index: 99;
    box-shadow: 0 0 120px 100px black;
    pointer-events: none;
    transition: 0.2s;
  }

  &.bottom:after {
    opacity: 0;
  }
}

.text-gradient {
  background: linear-gradient(137.28deg, #3884ff 14.61%, #2e52a1 93.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

$color: transparent;

.hero-text {
  position: relative;
  color: $color;
  background: linear-gradient(
    $color,
    $color 25%,
    #fff,
    #fff,
    $color 75%,
    $color
  );
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 100% 400%;
  background-position: center -50%;

  &.animate {
    animation-name: textScroll;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-fill-mode: forwards;
  }
}

@keyframes textScroll {
  from {
    background-position: center -50%;
  }
  to {
    background-position: center -90%;
  }
}

#rcc-decline-button {
  background: #c64646 !important;
}
